import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ProjectCards from "../components/ProjectCards";

const Work = () => {
  return (
    <Layout>
      <SEO title="Work" />
      <div className="grid grid-cols-none lg:grid-cols-1 max-w-[90%] lg:max-w-[70vw] mx-auto my-0">
        <ProjectCards />
      </div>
    </Layout>
  );
};

export default Work;
