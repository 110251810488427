import React from "react";

import { StaticImage } from "gatsby-plugin-image";

const Farmature = () => {
  const path = "../../images/farmature.png";

  return (
    <>
      <div className="mx-1 mt-12 mb-4 md:m-14">
        <p className="mx-0 mb-8 mt-0 lg:mt-0 lg:mb-8 text-[#179992] text-2xl font-semibold">
          #2. Farmature - Land Renting For Natural Farming
        </p>
        <a
          href="https://farmature.herokuapp.com/"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src={path}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="project card"
            placeholder="blurred"
            className="rounded-lg shadow-xl"
          />
        </a>
      </div>
    </>
  );
};

export default Farmature;
