import React from "react";

import Design from "./Projects/Design";
import Mooovie from "./Projects/Mooovie";
import Farmature from "./Projects/Farmature";
import Music from "./Projects/Music";

const ProjectCards = ({ path, index }) => {
  console.log(path, index);

  return (
    <>
      <Mooovie />
      <Farmature />
      <Design />
      <Music />
    </>
  );
};

export default ProjectCards;
